<template>
    <div class="p-2 collab-doc">
        <app-heading-3>Add Collaborators</app-heading-3>
        <div class="signature-card p-1 my-1">
            <p class="small-text mb-0">You can only add collaborators that are already part of the project. If you want to add new one please add them in the project first.</p>
            <!-- <router-link :to="{path:'/settings',query:{tab:'signature'}}" class="small-text text-primary text-underline">Open Settings</router-link> -->
        </div>
        <div class="mb-1" v-if="collaborator_list.length>0">
            <div class="collaborator-card d-flex align-items-center mt-1" v-for="(c,index) in collaborator_list" :key="index">
                <div class="email-avatar">{{c.email.slice(0,1).toUpperCase()}}</div>
                <div>
                    <app-heading-3>{{c.name}}</app-heading-3>
                    <p class="text-small mb-0">{{getSlicedEmail(c.email)}}</p>
                </div>
                <feather-icon icon="XIcon" size="22" class="ml-auto cursor-pointer" @click="removeCollaburator(index,c)"></feather-icon>
            </div>
            <b-button variant="primary" class="mt-1" @click="addMore()" v-if="!show">Add More Collaborators</b-button>
        </div>
        <div v-if="show || collaborator_list.length<1">
            <app-text-input label="Name" v-model="collaborator_details.name" placeholder="Jhon Doe"/>
            <div class="position-relative" >
                <app-text-input   label="Email Address" v-model="collaborator_details.email" @onKeyup="fetchCollaburators" @onFocus="fetchCollaburators" placeholder="jhon.doe@gmail.com"/>
                <div class="sugestion-list" v-if="!hide_suggestions && suggestions.length">
                    <div class="suggestion" v-for="(s,index) in suggestions" :key="index" @click="selectSuggetion(s)">{{s.email}}</div>
                </div>
            </div>
            <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block "
            >
                <b-button variant="primary" :disabled="!collaborator_details.id" @click="addCollaborator()">Add</b-button>
            </b-overlay>
        </div>
    </div>
</template>
<script>
import AppHeading2 from "@/components/app-components/AppHeading2.vue"
import AppHeading3 from "@/components/app-components/AppHeading3.vue"
import AppSimpleButton from "@/components/app-components/AppSimpleButton.vue"
import AppTextInput from "@/components/app-components/AppTextInput.vue"
import {search_user_by_email, add_collaburator, remove_collaburator, search_collaborator_by_project} from "@/apis/others/documents"
import { BButton, BPopover, BOverlay } from "bootstrap-vue"
export default {
    components: {
        AppHeading2,
        AppHeading3,
        AppSimpleButton,
        BButton,
        AppTextInput,
        BPopover,
        BOverlay
    },
    props:{
        page:[String, Number]
    },
    data(){
        return {
            collaborator_details:{
                name:'',
                email:'',
                id:''
            },
            collaborators:[],
            suggestions:[],
            collaborator_list:[],
            projectId:'',
            hide_suggestions:false,
            show:true,
            loading:false
        }
    },
    computed:{
        showSuggestions(){
            console.log(this.suggestions.length);
            return this.suggestions.length>0?true:false
        }
    },
    methods:{
        addCollaborator(){
            this.loading=true
            // this.collaborators.push(this.collaborator_details.id)
            const collaborator_details = {
                x:0,
                y:this.collaborator_list.length*120+5,
                pageNo:this.page,
                collaboratorId:this.collaborator_details.id,
                email:this.collaborator_details.email
            }
            add_collaburator({id: this.$route.params.id,collaborator: collaborator_details}).then(resp=>{
                this.loading=false;
                const colaburatorDetails=this.collaborator_details
                this.$emit('onCollaboratorAdded',colaburatorDetails)
                this.addCollaboratorToList(this.collaborator_details)  
            }).catch(err=>{
                this.loading=false;
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                })
                this.show=false
            })
        },
        addCollaboratorToList(collaborator_details){
            const data={...collaborator_details}
            this.collaborator_list.push(data)
            this.collaborator_details.name=''
            this.collaborator_details.email=''
            this.collaborator_details.id=''
            this.show=false
            
        },
        addMore(){
            this.show=true
        },
        fetchCollaburators(){
            search_collaborator_by_project({id:this.projectId,email:this.collaborator_details.email}).then(resp=>{
                this.suggestions=resp.data.collaborators
            })
            this.hide_suggestions=false
        },
        selectSuggetion(s){
            this.collaborator_details.email=s.email
            this.collaborator_details.id=s._id
            this.collaborator_details.name=`${s.first_name?s.first_name:''} ${s.last_name?s.last_name:''} `
            this.suggestions=[]
            this.hide_suggestions=true
        },
        removeCollaburator(i,c){
            remove_collaburator({id:this.$route.params.id, collaborator:c.id}).then(resp=>{
                this.collaborator_list.splice(i,1)
                this.$emit('onRemove',c)
            })
        },
        getSlicedEmail(email){
            return email.length <=20 ? email: email.slice(0,20)+'...'
        }
    },
}
</script>
<style scoped>
.sugestion-list{
    max-height: 100px;
    overflow-y: auto;
    margin-bottom: 8px;
    position: absolute;
    top: 60px;
}
.suggestion{
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}
.suggestion:hover{
    background-color: #ecf2ff;
}

.sugestion-list::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .sugestion-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgb(226, 225, 225);
    border-radius: 10px;
  }
  
  /* Handle */
  .sugestion-list::-webkit-scrollbar-thumb {
    background: #ecf2ff;
    border-radius: 5px;
  }
  .collaborator-card{
    padding: 12px;
    border-radius: 8px;
    background-color: #e6fcff;
}
.email-avatar{
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
    height: 36px;
    width:36px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 18px;
    font-family: 400;
    color: #000000;
    background-color: #a1f3ff;
}
.signature-card{
    border-radius: 6px;
    background-color: #f8fafc;
}
.small-heading{
    line-height: 16px;
    font-weight: 400;
    size:13px;
    color: #000000;

}
.text-small{
    font-size:14px;
    font-weight: 400;
    line-height: 16px;
    color:#4B465C;
}
.collab-doc{
    height: 380px;
    overflow-y: auto;
}
.collab-doc::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .collab-doc::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .collab-doc::-webkit-scrollbar-thumb {
    background: lightgray; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .collab-doc::-webkit-scrollbar-thumb:hover {
    background: #004bff; 
  }
  @media(max-width:425px){
    .collab-doc{
        height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
    }
  }
</style>